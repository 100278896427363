<template>
<div id="desc-long" class="big-m-b m-t-nav">
    <h2 class="wrap tt-up p-l p-r ta-j">
        <div>
<!-- is a music exploration platform designed to attract and determine the best independent music and musicians in your local neighborhood, in your city, in your country and on planet Earth. -->
is a music platform designed to discover and rank the best independent artists and music locally, nationally, and globally.
        </div>
        <br>
<p class="grey">The ranking algorithm</p>
        <div class="t-i">is based on a dynamic ratio determined by how many total interactions divided by how many unique plays a song or artist currently have accumulated. This to create an egalitarian chart system based on the algorithmic vote of the community.
        </div>
        <br>
<p class="grey">Filtering</p>
        <div class="t-i">
on area and genre also provides a publishing mechanism for artists to autonomously grow from a local to global audience.
        </div>
        <br>
<p class="grey">Manifesto: </p>
        <div class="t-i d-f fd-c">
<div class="fs-i"><span class="grey nums">1—</span>Create, dance, make love. </div>
<div class="fs-i"><span class="grey nums">2—</span>Express yourself (unrelentlessly). </div>
<div class="fs-i"><span class="grey nums">3—</span>Never compromise. </div>
<div class="fs-i"><span class="grey nums">4—</span>Inquire intently. </div>
<div class="fs-i"><span class="grey nums">5—</span>Explore boldly. </div>
<div class="fs-i"><span class="grey nums">6—</span>Create creatively. </div>
<div class="fs-i"><span class="grey nums">7—</span>F*** time. </div>
<div class="fs-i"><span class="grey nums">8—</span>F*** age. </div>
<div class="fs-i"><span class="grey nums">9—</span>F*** normal. </div>
<div class="fs-i"><span class="grey nums">10—</span>Feelings, ideas, ecstasy (over all). </div>
<div class="fs-i"><span class="grey nums">11—</span>Struggle is progress. </div>
<div class="fs-i"><span class="grey nums">12—</span>Make your mama proud. </div>
        </div>
        <br>
<p class="grey">Created by: </p>
        <div class="t-i">
<a class="text-link" href="https://mesh-innovation.com" target="_blank">MESH Innovation</a> - a web development studio, specialized in building and designing products for the internet.
        </div>
        <br>
<p class="grey">Say hello! </p>
        <div class="t-i">
Algorhythm is built to service all music lovers alike. If you have any ideas, thoughts or feedback please reach out to us. Algorhythm will always be a work in progress and we want to ideate, design and build its future together with you. Please come and say hello to us in any of our channels.
        </div>       
<div class="t-i"><a class="text-link" href="https://www.instagram.com/algorhythm.world/" target="_blank">Instagram</a></div>
<div class="t-i"><a class="text-link" href="https://discord.gg/R8HXGjPDFb" target="_blank">Discord</a></div> 
<div class="t-i"><a class="text-link" href="https://x.com/AlgorhythmFM" target="_blank">X.com</a></div>
<div class="t-i"><a class="text-link" href="mailto:email@mesh-in.com" target="_blank">E-mail</a></div>  


      </h2>
  </div>
</template>

<script>
export default {
    name: 'DescLong',
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#desc-long {
    .wrap {
        background-color: $brand-bg;
    }
    .nums {
        // width: 2rem;
    }
}
</style>